@font-face {
  font-family: 'icomoon';
  src:url('fonts/icomoon.eot?-kn6pr2');
  src:url('fonts/icomoon.eot?#iefix-kn6pr2') format('embedded-opentype'),
    url('fonts/icomoon.woff?-kn6pr2') format('woff'),
    url('fonts/icomoon.ttf?-kn6pr2') format('truetype'),
    url('fonts/icomoon.svg?-kn6pr2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-github:before {
  content: "\e600";
}

.icon-linkedin:before {
  content: "\e601";
}

.icon-twitter-02:before {
  content: "\e602";
}

