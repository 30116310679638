html {
  background-color: #e9e9e9;
}

.container {
  margin: 0 auto;
  max-width: 1020px;
  min-height: 970px;
  padding: 190px 0 0 0;
  background-image: url('/img/bg.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fff;
  background-size: 100%;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.2);
}

@media(max-width: 600px) {
  .container {
    background-size: 150%;
    padding: 100px 0 0 0;
  }
}

@media(max-width: 360px) {
  .container {
    background-size: 250%;
    padding: 20px 0 0 0;
  }
}

footer {
  text-align: center;
  padding: 0 0 60px 0;
  p {
    max-width: 60em;
    margin: 0 auto 20px auto;
    color: #999;
    font-size: 13px;
  }
}
