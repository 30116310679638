section.updates {

  margin: 0 24px 0 24px;

  li, p {
    color: #58585B;
    font-size: 13px;
    line-height: 160%;
    margin: 0 0 20px 0;
  }

  h5 {
    font-size: 13px;
    text-align: center;
    margin: 0 0 0 0;
    padding-bottom: 15px;
  }

  h6 {
    font-size: 12px;
    color: #58585B;
    margin: 0 0 2px 0;
    padding: 0;
  }

  .page-header {
    border-bottom: 5px solid #FFF2DD;
    margin-bottom: 15px;
  }

  .content {
    padding: 0 20px;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li.tweet {
    margin-bottom: 20px;
  }

  .time-ago {
    color: #ccc;
    font-size: 12px;
    font-style: italic;
  }

}