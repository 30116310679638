* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

.hr {
  height: 1px;
  width: 100%;
  margin: 80px 0 80px 0;
  background-color: rgba(0, 0, 0, 0.1);
}